<template>
  <div class="app-box">
    <van-nav-bar
  title="提现"
  left-arrow
  @click-left="onClickLeft"
/>
    <div class="cardholder">
      <p class="cardholder1">请绑定持卡人本人的银行卡</p>
      <p class="cardholder2">支持460家银行</p>
    </div>
    <div class="forListBg">
       <van-form @submit="onSubmit">
         <van-field
        v-model="forList.truename"
        type="text"
        label="已认证姓名"
        input-align="right"
        :rules="[{ required: true}]"
      />
      <van-field
        v-model="forList.id_card"
        type="text"
        label="已认证身份证"
        input-align="right"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="forList.card_holder"
        type="text"
        name="持卡人"
        label="持卡人"
        input-align="right"
        placeholder="请输入持卡人姓名"
        :rules="[{ required: true }]"
      />
         <van-field
        v-model="forList.bank_num"
        type="number"
        name="银行卡号"
        input-align="right"
        label="银行卡号"
        placeholder="请输入银行卡号"
        :rules="[{ required: true }]"
      />
       <van-field
        v-model="forList.bank"
        type="text"
        name="银行名称"
        input-align="right"
        label="银行名称"
        placeholder="请选择银行"
        readonly
        right-icon="arrow"
        @click="bancShow = true"
      />
      <van-field
        v-model="forList.pagcet"
        type="text"
        name="银行开户地"
        input-align="right"
        placeholder="请选择省份"
        label="银行开户地"
        right-icon="arrow"
        readonly
        @click="provShow = true"  
      />
       <van-field
        v-model="forList.bank_branch"
        type="text"
        name="开户行"
        input-align="right"
        label="开户行"
        placeholder="请输入开户行"
        :rules="[{ required: true }]"
      />
    <div class="texttitl">
      为保证您的账户资金安全，请绑定本人的银行卡，获取更多帮助
      请致电万维云客服:<a href="tel:400-861-8683">400-861-8683</a>
    </div>
      <!-- end -->
    <div class="boureBox">
        <van-button type="info" block>添加银行卡</van-button>
    </div>
    </van-form>
    </div>
    <!-- end -->
    <div>
      <van-popup v-model="provShow" position="bottom">
        <!-- <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          @close="provShow = false"
          @finish="onFinish"
          :field-names="fieldNames"
        /> -->
        <van-area title="标题" :area-list="areaList" @confirm="confirmClick" @cancel="provShow = false" />
      </van-popup>
    </div>
    <!-- 银行卡列表 -->
    <div>
      <van-popup v-model="bancShow" position="bottom">
        <van-picker
          show-toolbar
          :columns="bancList"
          @confirm="onConfirm"
          value-key="bank"
          @cancel="bancShow = false"
        />
      </van-popup>
    </div>
    
  </div>
</template>

<script>
import {banckAdd,getBankCode} from "@/request/api"
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      bancShow:false,
      areaList:areaList,
      forList: {
        truename:"",
        id_card:"",
        card_holder: "",
        bank:"",
        bank_branch:"",
        bank_num: "",
        bank_code: "",
        province: "",
        city: "",
        pagcet:""
      },
      fieldValue: "",
      provShow: false,
      cascaderValue: "",
      fieldNames: {
        value: "text",
      },
      bancList:[],
    };
  },
  mounted(){
    this.getbancCode();
  },
  computed:{
    ...mapState(['infoArr'])
  },
  methods: {
     confirmClick(data){
      this.forList.province = data[0].code
      this.forList.city = data[1].code
      this.forList.pagcet  = data[0].name + data[1].name +data[2].name
      this.provShow = false;
    },
    onClickLeft(){
      this.$router.push('/');
    },
    onSubmit(values) {
      banckAdd(this.forList).then(res=>{
        if(res.code == 2004){
          Toast.fail(res.message)
          return
        }
        Toast.success('添加成功');
        // 添加银行卡的时候认证信息为银行卡信息
        this.$router.push(this.$route.query.redirect || "/MySetUp"); // 返回原来的页面
      })
    },
  
    onFinish({ selectedOptions }) {
      // console.log(selectedOptions)
      this.forList.province = selectedOptions[0].text;
      this.forList.city = selectedOptions[1].text;
      this.provShow = false;
      this.forList.pagcet = selectedOptions
        .map((option) => option.text)
        .join("/");
    },
    async getbancCode(){
      let res = await getBankCode();
      this.bancList = res.data;
      // end
      this.forList.truename = this.infoArr.truename;
      this.forList.id_card = this.infoArr.id_card
    },
    onConfirm(value, index) {
      this.forList.bank = value.bank;
      this.forList.bank_code = value.code;
      this.bancShow = false;
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #edf2f5ff;
  box-sizing: border-box;
}
.texttitl {
  color: #767e94ff;
  padding: 10px 20px;
  font-size: 14px;
}
.boureBox{
    width: 90%;
    margin: auto;
}
.boureBox ::v-deep .van-button{
    border-radius: 10px;
}
.cardholder{
  text-align: center;
 background: #fff;
}
.cardholder1{
  padding: 5px 0;
}
.cardholder2{
  font-size: 14px;
  color: #999DADFF;
}
.forListBg{
  background: #fff;
  padding-bottom: 10px;
}
::v-deep .van-nav-bar .van-icon {
		color: #000;
	}
</style>